@tailwind components;

@layer components {
  .sidebar-icon.active {
    @apply relative flex items-center justify-center 
    h-12 w-12 mt-2 mb-2 mx-auto  
  bg-violet-600 dark:bg-violet-900
   text-white 
    rounded-xl 
    transition-all duration-300 ease-linear
    cursor-pointer shadow-lg dark:shadow-lg;
  }
  .sidebar-icon {
    @apply relative flex items-center justify-center 
    h-12 w-12 mt-2 mb-2 mx-auto
    dark:text-white  
  bg-gray-200 hover:bg-violet-600 dark:bg-gray-800 dark:hover:bg-violet-900
  text-purple-500 hover:text-white
    hover:rounded-xl rounded-3xl
    transition-all duration-300 ease-linear
    cursor-pointer shadow-lg dark:shadow-lg;
  }

  .sidebar-tooltip {
    @apply absolute w-auto p-2 m-2 min-w-max bottom-0 left-14 rounded-md shadow-md
    text-white bg-violet-600  dark:bg-gray-900 dark:text-white
    text-xs font-bold 
    transition-all duration-100 scale-0 xl:group-hover:scale-100 origin-left;
  }

  .sidebar-hr {
    @apply bg-gray-200 dark:bg-gray-800 
    border border-gray-200 dark:border-gray-800 rounded-full
    mx-2;
  }
  .top-navigation-icon {
    @apply text-gray-500
    mr-3 ml-4
    transition duration-300 ease-in-out 
    hover:text-pink-400 
    cursor-pointer;
  }
}
