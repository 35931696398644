@import-normalize;
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.background-animate {
  background-size: 400%;

  -webkit-animation: AnimationName 7s ease infinite;
  -moz-animation: AnimationName 7s ease infinite;
  animation: AnimationName 7s ease infinite;
}

@keyframes AnimationName {
  0%,
  100% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  @apply bg-gray-500 dark:bg-purple-400;
}

.ak-container {
  width: 100%;
}
@media (min-width: 641px) {
  .ak-container {
    max-width: 640px;
  }
}
@media (min-width: 770px) {
  .ak-container {
    max-width: 768px;
  }
}
@media (min-width: 1025px) {
  .ak-container {
    max-width: 1024px;
  }
}
@media (min-width: 1281px) {
  .ak-container {
    max-width: 1280px;
  }
}
@media (min-width: 1537px) {
  .ak-container {
    max-width: 1536px;
  }
}
